import RBAPI from "roadbotics-api-wrapper";
import firebase from "config/firebase";

const roadBoticsAPI = new RBAPI(
  process.env.REACT_APP_ROADWAY_API_BASE,
  `${process.env.REACT_APP_ACCOUNTS_DOMAIN}/login`,
  firebase
);

export default roadBoticsAPI;

import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import { RB_YELLOW } from "../constants/colors";
import ActivityIndicatorButton from "./ActivityIndicatorButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import sanitizeToCleanHTML from "../utils/sanitizeToCleanHTML"

const useStyles = makeStyles({
  card: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    marginTop: "100px",
    padding: "20px",
    marginBottom: "60px",
  },
  cardHeader: {
    backgroundColor: RB_YELLOW,
    textAlign: "center",
  },
  acceptActions: {
    paddingTop: "20px",
    paddingBottom: "20px",
    display: "flex",
    flexDirection: "row",
  },
  actions: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    backgroundColor: RB_YELLOW,
  },
  usEntityActions: {
    paddingBottom: "20px",
  },
});

const renderContent = (content: string): React.ReactNode => {
  const sanitizedContent = sanitizeToCleanHTML(content)
  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
};

export interface TOSViewInterface {
  content: string;
  tosAgreed: boolean;
  registeredUSEntityPicked: boolean | null;
  handleClickTosAgreed: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  handleClickAccept: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleChooseRegisteredUSEntity: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  isActiveAcceptButton: boolean;
  isPriorTOSAccepted: boolean;
}

const TOSView = ({
  content,
  tosAgreed,
  registeredUSEntityPicked,
  handleClickTosAgreed,
  handleClickAccept,
  handleChooseRegisteredUSEntity,
  isActiveAcceptButton,
  isPriorTOSAccepted,
}: TOSViewInterface): JSX.Element => {
  const classes = useStyles();
  return (
    <section>
      <Card className={classes.card}>
        {isPriorTOSAccepted && (
          <CardHeader
            id={`test-updated-card-header`}
            className={classes.cardHeader}
            title="OUR TERMS AND CONDITIONS HAVE BEEN UPDATED"
          />
        )}
        <CardContent style={{ maxHeight: "60vh", overflow: "scroll" }}>
          {renderContent(content)}
          <Divider />
        </CardContent>
        <CardActions>
          <div className={classes.actions}>
            <div className={classes.usEntityActions}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="registered_us_entity"
                  name="registered_us_entity"
                  onChange={handleChooseRegisteredUSEntity}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio style={{ color: "#000" }} />}
                    label="I am NOT a registered US entity"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio style={{ color: "#000" }} />}
                    label="I am a registered US entity"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Divider />
            <div className={classes.acceptActions}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={`test-checkbox`}
                    style={{ color: "#000" }}
                    checked={tosAgreed}
                    onChange={handleClickTosAgreed}
                  />
                }
                label="I have read and accept the terms of service"
              />

              <ActivityIndicatorButton
                id={`test-activity-indicator-button`}
                disabled={!tosAgreed || registeredUSEntityPicked == null}
                isActive={isActiveAcceptButton}
                progressSize={14}
                disableButtonWhileActive={true}
                type={"button"}
                className={classes.button}
                onClick={handleClickAccept}
              >
                ACCEPT
              </ActivityIndicatorButton>
            </div>
          </div>
        </CardActions>
      </Card>
    </section>
  );
};

export default TOSView;

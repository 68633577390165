import firebase from "../config/firebase";
import axios from "axios";

const makeBlob = geoJson => {
  const str = JSON.stringify(geoJson.data.data);
  const bytes = new TextEncoder().encode(str);
  const blob = new Blob([bytes], {
    type: "application/json;charset=utf-8",
  });

  return blob;
};

export const downloadMapFile = async (scan, type, featureType) => {
  const idToken = await firebase.auth().currentUser?.getIdToken();
  let payload;

  payload = await axios.get(
    // eslint-disable-next-line max-len
    `${process.env.REACT_APP_ROADWAY_API_BASE}/download-map/${type}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      params: {
        scanName: scan.name,
        scanId: scan.id,
        featureType: featureType,
      },
    }
  );

  if (type === "geojson" || type === "roadsense") {
    payload = makeBlob(payload);
  }

  return payload;
};

import React, { useState } from "react";
import AppBarContainer from "../containers/AppBarContainer";
import Paper from "@material-ui/core/Paper";
import FullAnalysisChart from "./FullAnalysisChart";
import StreetTypeAnalysisChart from "./StreetTypeAnalysisChart";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { MeasuringSystem } from "../constants/measuringSystem";
import { AnalysisInterface } from "../interfaces/analysis";
import { Card, CardContent, Typography } from "@material-ui/core";
import BottomBar from "./BottomBar";
import IDIAnalysis from "./IDIAnalysis";
import { IDI, SegmentDistressStatistics } from "interfaces/IDIAnalysis";
import { useSelector } from "react-redux";
import State from "interfaces/state";
import { useTracking } from "react-tracking";

const useStyles = makeStyles(theme => ({
  summaryPageWrapper: {
    paddingTop: "5%",
  },
  headerWrapper: {
    margin: "0px 20px",
  },
  fullAnalysisChartWrapper: {
    backgroundColor: "#f9f7f6",
    margin: "0px 20px 40px",
  },
  title: {
    fontSize: theme.typography.pxToRem(34),
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
  },
  activeTab: {
    fontWeight: "bold",
    fontSize: "28px",
    fontFamily: "'Open Sans', sans-serif",
  },
  deactiveTab: {
    fontSize: "28px",
    fontFamily: "'Open Sans', sans-serif",
  },
  tabs: {
    backgroundColor: "#f9f7f6",
    display: "inline-block",
  },
}));

export type StreetData = {
  name: string;
  ratingDistribution: { [key: string]: number };
  avgRating: number;
  key: number;
  totalLength: number;
  pci: number;
  segmentDistressStatistics: SegmentDistressStatistics;
};

type SummaryPageProps = {
  location: {
    pathname: string;
  };
  analysisData: AnalysisInterface;
  measuringSystem: MeasuringSystem;
  classificationAnalysisList: StreetData[];
  isNetworkDataLoaded: boolean;
  individualRoadsAnalysisList: [];
  isStreetDataLoaded: boolean;
  isIDIDataLoaded: boolean;
  IDIData?: IDI;
  showErrorMessage: boolean;
};

export enum SummaryTabs {
  ROAD_REPORT = "Road Report Analysis",
  INDIVIDUAL_DISTRESS = "Individual Distress Analysis",
}

const SummaryPage = ({
  location,
  analysisData,
  measuringSystem,
  classificationAnalysisList,
  isNetworkDataLoaded,
  individualRoadsAnalysisList,
  isStreetDataLoaded,
  isIDIDataLoaded,
  IDIData,
  showErrorMessage,
}: SummaryPageProps): React.ReactElement => {
  const tracking = useTracking();
  const [tabSelection, setTabSelection] = useState<SummaryTabs>(
    SummaryTabs.ROAD_REPORT
  );
  const hasIDIData = useSelector(
    (state: State) => state?.userData?.analysisData.hasIDIData
  );
  const styles = useStyles();
  let tabs: SummaryTabs[] = [SummaryTabs.ROAD_REPORT];
  const tabsToShow = hasIDIData
    ? [...tabs, SummaryTabs.INDIVIDUAL_DISTRESS]
    : tabs;

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: SummaryTabs) => {
    setTabSelection((prevSelection: SummaryTabs) => {
      tracking.trackEvent({
        event: "mouse-click",
        action: `analysis-tab-${prevSelection}-click`,
      });

      return Object.values(SummaryTabs).includes(newValue)
        ? newValue
        : prevSelection;
    });
  };

  return (
    <div>
      <AppBarContainer urlLocation={location.pathname} />
      <div className={styles.summaryPageWrapper}>
        <Card>
          <CardContent>
            <div className={styles.headerWrapper}>
              <Typography className={styles.title} gutterBottom>
                <i>{analysisData.displayName}</i>
              </Typography>
              <BottomBar
                withDivider={false}
                containerStyle={styles.tabs}
                modalTab={tabSelection}
                handleTabChange={handleTabChange}
                tabsToShow={tabsToShow}
              />
            </div>
            {tabSelection === SummaryTabs.ROAD_REPORT ? (
              <div>
                <Paper className={styles.fullAnalysisChartWrapper}>
                  <FullAnalysisChart
                    analysisData={analysisData}
                    classificationAnalysisList={classificationAnalysisList}
                    measuringSystem={measuringSystem}
                    isDataLoaded={isNetworkDataLoaded}
                  />
                </Paper>
                {classificationAnalysisList.map(streetData => {
                  const streetList =
                    individualRoadsAnalysisList[streetData.key];
                  return (
                    <StreetTypeAnalysisChart
                      key={`classification-analysis-${streetData.name}`}
                      streetData={streetData}
                      measuringSystem={measuringSystem}
                      streetList={streetList}
                      isDataLoaded={isStreetDataLoaded}
                    />
                  );
                })}
              </div>
            ) : (
              <Paper className={styles.fullAnalysisChartWrapper}>
                <IDIAnalysis dataLoaded={isIDIDataLoaded} showErrorMessage={showErrorMessage} IDIData={IDIData} individualRoadsAnalysisList={individualRoadsAnalysisList}/>
              </Paper>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SummaryPage;

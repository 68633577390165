import Membership from "../interfaces/membership";

export const ORG_ROADBOTICS = "RoadBotics";
export const ROLE_ADMIN = "Admin";

export const isRoadBoticsAdmin = (memberships: Membership[]) =>
  memberships.some(
    ({ organization, role }) =>
      organization === ORG_ROADBOTICS && role === ROLE_ADMIN
  );

export const isAdmin = (memberships: Membership[]) =>
  memberships.some(({ role }) => role === ROLE_ADMIN);

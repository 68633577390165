import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import { RB_LIGHT_SHADOW } from "constants/colors";
import {SummaryTabs} from "./SummaryPage";

interface BottomBarProps {
  modalTab: string;
  handleTabChange: (_: React.ChangeEvent<{}>, newValue: SummaryTabs) => void;
  tabsToShow: string[];
  containerStyle: string;
  withDivider: boolean;
}

const useStyles = makeStyles({
  divContainer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    zIndex: 100,
    backgroundColor: "white",
    maxHeight: "38px",
    marginLeft: "-8px",
    overflowX: "hidden",
  },
  tabs: {
    maxHeight: "35px",
    backgroundColor: RB_LIGHT_SHADOW,
  },
  indicator: {
    top: 0,
    backgroundColor: "#fad201",
    height: "8px",
  },
  selectedTab: {
    backgroundColor: 'inherit',
  },
});

const BottomBar = ({
  modalTab,
  handleTabChange,
  tabsToShow,
  containerStyle,
  withDivider,
}: BottomBarProps): React.ReactElement => {
  const classes = useStyles();
  const divContainer = containerStyle ? containerStyle : classes.divContainer;

  return (
    <div className={divContainer}>
      {withDivider && <Divider />}
      <Tabs
        value={modalTab}
        onChange={handleTabChange}
        TabIndicatorProps={{ className: classes.indicator }}
      >
        {tabsToShow.map(tab => (
          <Tab
            classes={{ selected: classes.selectedTab }}
            className={classes.tabs}
            key={tab}
            value={tab}
            label={tab}
          />
        ))}
      </Tabs>
    </div>
  );
};

BottomBar.defaultProps = {
  withDivider: true,
};

export default BottomBar;
